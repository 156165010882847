<template>
  <div class="five-steps">
    <div class="how-does-it-works">
      <div class="how-does-it-works-info">
        <h1 style="color: #25a4e1">Steps to Launch</h1>
        <p>
          Create your customized subscription experience and launch to your
          clients in just a couple of days.
        </p>
      </div>
    </div>

    <div
      v-motion
      class="five-steps-info"
      :initial="{
        opacity: 0,
        y: 100,
      }"
      :visibleOnce="{
        opacity: 1,
        y: 0,
        transition: {
          delay: 100,
          stiffness: 200,
          damping: 90,
          mass: 3,
        },
      }"
    >
      <div
        v-motion
        class="first-3-steps"
        :initial="{
          opacity: 0,
          y: 100,
        }"
        :visibleOnce="{
          opacity: 1,
          y: 0,
          transition: {
            delay: 100,
            stiffness: 200,
            damping: 90,
            mass: 3,
          },
        }"
      >
        <div class="step">
          <img src="/img/step-img1-min.png" class="img1" alt="" />
          <h2>Meet with Us</h2>
          <p>Book a 30-minute meeting with us to learn more about HelixPay.</p>
        </div>
        <div class="step">
          <img src="/img/step-img2-min.png" class="img2" alt="" />
          <h2>Simple Requirements</h2>
          <p>Simple KYC process & no-strings service agreement.</p>
        </div>
        <div class="step">
          <img src="/img/step-img3-min.png" class="img3" alt="" />
          <h2>Set up your store</h2>
          <p>
            Customize the look and feel of your account and subscription
            options.
          </p>
        </div>
        <div class="step hide-web-show-mobile">
          <img src="/img/step-img4-min.png" class="img4" alt="" />
          <h2>Launch!</h2>
          <p>Launch your subscriptions with your clients!</p>
        </div>
      </div>
    </div>

    <div
      v-motion
      class="second-2-steps"
      :initial="{
        opacity: 0,
        y: 100,
      }"
      :visibleOnce="{
        opacity: 1,
        y: 0,
        transition: {
          delay: 100,
          stiffness: 200,
          damping: 90,
          mass: 3,
        },
      }"
    >
      <div class="step show-web-hide-mobile">
        <img src="/img/step-img4-min.png" class="img4" alt="" />
        <h2>Launch!</h2>
        <p>Launch your subscriptions with your clients!</p>
      </div>
      <div class="step">
        <img src="/img/step-img5-min.png" class="img5" alt="" />
        <h2>Grow Earnings</h2>
        <p>
          Use HelixPay engagements tools & analytics to grow your recurring
          earnings.
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.how-does-it-works {
  width: 100%;
}

.how-does-it-works-info {
  width: 40rem;
  margin: auto;
  text-align: center;
}

.how-does-it-works-info h1 {
  font-size: 2rem;
  font-weight: 700;
}

.how-does-it-works-info p {
  width: 70%;
  margin: auto;
  opacity: 0.6;
  font-weight: 500;
  font-size: 1rem;
  margin-top: 0.5rem;
  line-height: 2;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ 5 STEPS */

.five-steps {
  width: 100%;
  line-height: 1.5;
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: #f3f7f8;
}

.five-steps-info {
  width: 70rem;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

.first-3-steps {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  padding-top: 3rem;
}

.first-3-steps p {
  font-weight: 500;
  opacity: 0.6;
}

.second-2-steps {
  width: 45rem;
  margin: auto;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.second-2-steps p {
  font-weight: 500;
  opacity: 0.6;
}

.step {
  padding: 2rem;
  border-radius: 5px;
  align-items: center;
  align-content: center;
  text-align: center;
  transition: 0.3s ease-in-out;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.step:active,
.step:hover {
  transform: scale(1.01);
}

.step h2 {
  font-weight: 700;
  font-size: 1.3rem;
  margin-top: 1rem;
}

.step p {
  line-height: 2;
  margin-top: 0.5rem;
}

.step .img1 {
  width: 45%;
  height: inherit;
}

.step .img2 {
  width: 40%;
  height: inherit;
  margin: 1.9rem;
}

.step .img3 {
  width: 30%;
  height: inherit;
  margin: 0.45rem;
}

.step .img4 {
  width: 45%;
  height: inherit;
  margin: 0.45rem;
}

.step .img5 {
  width: 22%;
  height: inherit;
  margin: 0.45rem;
}

.hide-web-show-mobile {
  display: none;
}

@media only screen and (max-width: 1400px) {
  .five-steps-info {
    width: 60rem;
  }

  .first-3-steps {
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
  }

  .second-2-steps {
    width: 39.7rem;
  }

  .img1 {
    margin: 1rem;
  }

  .img2 {
    padding-bottom: 1rem;
  }

  .img3 {
    padding-bottom: 2rem;
  }

  .img4,
  .img5 {
    padding-bottom: 0.5rem;
  }
}

@media only screen and (max-width: 1050px) {
  .five-steps-info {
    width: 40rem;
    margin: auto;
  }

  .first-3-steps {
    width: 100%;
    grid-template-columns: 1fr 1fr;
  }

  .second-2-steps {
    width: 19rem;
    grid-template-columns: 1fr;
  }

  .hide-web-show-mobile {
    display: block;
  }

  .show-web-hide-mobile {
    display: none;
  }
}

@media only screen and (max-width: 650px) {
  .how-does-it-works-info {
    width: 90%;
  }

  .how-does-it-works-info h1 {
    font-size: 1.5rem;
  }

  .how-does-it-works-info p {
    width: 100%;
  }

  .five-steps-info {
    width: 90%;
    margin: auto;
  }

  .first-3-steps {
    width: 100%;
    grid-template-columns: 1fr;
  }

  .second-2-steps {
    width: 90%;
    margin: auto;
    margin-top: 2rem;
    grid-template-columns: 1fr;
  }

  .launch {
    width: 100%;
  }

  .launch-info {
    width: 90%;
    margin: auto;
  }

  .launch h1 {
    font-size: 1.3rem;
  }

  .cta-btn-launch {
    display: grid;
    text-align: center;
    margin: auto;
    width: 50%;
  }

  .launch-signup {
    font-size: 1rem;
    font-weight: 600;
  }

  .launch-schedule {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 0.5rem;
  }
}
</style>
